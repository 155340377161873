exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-statement-js": () => import("./../../../src/pages/cookie-statement.js" /* webpackChunkName: "component---src-pages-cookie-statement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-fashion-js": () => import("./../../../src/pages/projects/fashion.js" /* webpackChunkName: "component---src-pages-projects-fashion-js" */),
  "component---src-pages-projects-graphic-js": () => import("./../../../src/pages/projects/graphic.js" /* webpackChunkName: "component---src-pages-projects-graphic-js" */),
  "component---src-pages-projects-textile-js": () => import("./../../../src/pages/projects/textile.js" /* webpackChunkName: "component---src-pages-projects-textile-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-templates-author-project-js": () => import("./../../../src/pages-templates/authorProject.js" /* webpackChunkName: "component---src-pages-templates-author-project-js" */),
  "component---src-pages-templates-brand-project-js": () => import("./../../../src/pages-templates/brandProject.js" /* webpackChunkName: "component---src-pages-templates-brand-project-js" */),
  "component---src-pages-templates-fashion-textile-projects-js": () => import("./../../../src/pages-templates/fashionTextileProjects.js" /* webpackChunkName: "component---src-pages-templates-fashion-textile-projects-js" */),
  "component---src-pages-templates-hobbies-js": () => import("./../../../src/pages-templates/hobbies.js" /* webpackChunkName: "component---src-pages-templates-hobbies-js" */),
  "component---src-pages-templates-home-textile-projects-js": () => import("./../../../src/pages-templates/homeTextileProjects.js" /* webpackChunkName: "component---src-pages-templates-home-textile-projects-js" */)
}

